<template>
  <ColorsForm
    :color="color ? color : getCurrentColor()"
    :type="'edit'"
    @save="updateColor"
  />
</template>

<script>
import beforeLeaveRouteModal from '@/mixins/beforeLeaveRouteModal.js'
import checkProfileRoutePermission from '../../../mixins/checkProfileRoutePermission'
export default {
  components: {
    ColorsForm: () => import('./ColorsForm'),
  },
  mixins: [beforeLeaveRouteModal, checkProfileRoutePermission],
  data: () => ({
    color: {},
    askToLeave: true,
  }),
  methods: {
    getCurrentColor() {
      this.$api
        .get(`product_colors/${this.$route.params.id}`,{
          headers: {
            Authorization: `Bearer ${this.$cookies.get('token')}`,
          }
        })
        .then((res) => {
          this.color = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    updateColor() {
      this.askToLeave = false
      const formData = new FormData()
      const colorKeys = Object.keys(this.color)
      console.log("DBG UPDATE COLOR: ",{colorKeys});
      const colorValues = Object.values(this.color)

      for (let i = 0; i < colorKeys.length; i++) {
        if (colorValues[i] !== null) {
          formData.append(colorKeys[i], colorValues[i])
        }
      }

      this.$api
        .post(`product_colors/${this.$route.params.id}`
          ,formData
          ,{
            headers: {
              Authorization: `Bearer ${this.$cookies.get('token')}`,
            }
          }
        )
        .then(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'success',
            text: 'Cor atualizada com sucesso',
            confirm: () => {
              this.$router.push({ name: 'colors' })
            },
          })
        })
        .catch(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: 'Ops! Falha ao tentar atualizar esta cor',
            caption: 'Verifique se os campos estão preenchidos corretamente.',
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
        })
    },
  },
  created() {
    this.getCurrentColor()
  },
  beforeRouteLeave(to, from, next) {
    if (this.askToLeave) {
      this.openModal(
        () => {
          next()
        }, // Caso o modal seja confirmado
        () => {
          next(false)
        } // Caso o modal seja negado
      )
    } else {
      next()
    }
  },
}
</script>
